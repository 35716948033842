
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      banners: [],
      coupons: [],
      notfication_settings: [],
      order_deatiles: [],
      products: [],
      products_size: [],
      users_roles: [],
      vendor_balance: [],
      vendor_currenices: [],
      vendor_marketers: [],
      vendor_subscriptions: [],

      UsersDateils: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsersDateils: null,
      UsersDateilsSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addUsersDateils() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addUsersDateils",
        inputs: [
          {
            "model": "user_id",
            "type": "text",
            "label": "user_id"
          },
          {
            "model": "is_main",
            "type": "text",
            "label": "is_main"
          },
          {
            "model": "brand_name",
            "type": "text",
            "label": "brand_name"
          },
          {
            "model": "vendor_category_id",
            "type": "text",
            "label": "vendor_category_id"
          },
          {
            "model": "vendor_sub_category_id",
            "type": "text",
            "label": "vendor_sub_category_id"
          },
          {
            "model": "image",
            "type": "text",
            "label": "image"
          },
          {
            "model": "city_id",
            "type": "text",
            "label": "city_id"
          },
          {
            "model": "hood_id",
            "type": "text",
            "label": "hood_id"
          },
          {
            "model": "location",
            "type": "text",
            "label": "location"
          },
          {
            "model": "registry_id",
            "type": "text",
            "label": "registry_id"
          },
          {
            "model": "official_name",
            "type": "text",
            "label": "official_name"
          },
          {
            "model": "official_tel",
            "type": "text",
            "label": "official_tel"
          },
          {
            "model": "official_email",
            "type": "text",
            "label": "official_email"
          },
          {
            model: "banners",
            type: "select",
            options: (() => {
              return this.banners.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.banners",
          },

          {
            model: "coupons",
            type: "select",
            options: (() => {
              return this.coupons.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.coupons",
          },

          {
            model: "notfication_settings",
            type: "select",
            options: (() => {
              return this.notfication_settings.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.notfication_settings",
          },

          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles",
          },

          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles",
          },

          {
            model: "products",
            type: "select",
            options: (() => {
              return this.products.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.products",
          },

          {
            model: "products_size",
            type: "select",
            options: (() => {
              return this.products_size.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.products_size",
          },

          {
            model: "users_roles",
            type: "select",
            options: (() => {
              return this.users_roles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.users_roles",
          },

          {
            model: "vendor_balance",
            type: "select",
            options: (() => {
              return this.vendor_balance.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_balance",
          },

          {
            model: "vendor_currenices",
            type: "select",
            options: (() => {
              return this.vendor_currenices.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_currenices",
          },

          {
            model: "vendor_marketers",
            type: "select",
            options: (() => {
              return this.vendor_marketers.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_marketers",
          },

          {
            model: "vendor_subscriptions",
            type: "select",
            options: (() => {
              return this.vendor_subscriptions.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_subscriptions",
          },


        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("users-dateils", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editUsersDateils(app) {
      var data = {
        title: "popups.users_dateils",
        inputs: [
          {
            "model": "user_id",
            "type": "text",
            "label": "user_id",
            "value": app.user_id
          },
          {
            "model": "is_main",
            "type": "text",
            "label": "is_main",
            "value": app.is_main
          },
          {
            "model": "brand_name",
            "type": "text",
            "label": "brand_name",
            "value": app.brand_name
          },
          {
            "model": "vendor_category_id",
            "type": "text",
            "label": "vendor_category_id",
            "value": app.vendor_category_id
          },
          {
            "model": "vendor_sub_category_id",
            "type": "text",
            "label": "vendor_sub_category_id",
            "value": app.vendor_sub_category_id
          },
          {
            "model": "image",
            "type": "text",
            "label": "image",
            "value": app.image
          },
          {
            "model": "city_id",
            "type": "text",
            "label": "city_id",
            "value": app.city_id
          },
          {
            "model": "hood_id",
            "type": "text",
            "label": "hood_id",
            "value": app.hood_id
          },
          {
            "model": "location",
            "type": "text",
            "label": "location",
            "value": app.location
          },
          {
            "model": "registry_id",
            "type": "text",
            "label": "registry_id",
            "value": app.registry_id
          },
          {
            "model": "official_name",
            "type": "text",
            "label": "official_name",
            "value": app.official_name
          },
          {
            "model": "official_tel",
            "type": "text",
            "label": "official_tel",
            "value": app.official_tel
          },
          {
            "model": "official_email",
            "type": "text",
            "label": "official_email",
            "value": app.official_email
          },

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("users-dateils", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateUsersDateils(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            "model": "user_id",
            "type": "text",
            "label": "user_id"
          },
          {
            "model": "is_main",
            "type": "text",
            "label": "is_main"
          },
          {
            "model": "brand_name",
            "type": "text",
            "label": "brand_name"
          },
          {
            "model": "vendor_category_id",
            "type": "text",
            "label": "vendor_category_id"
          },
          {
            "model": "vendor_sub_category_id",
            "type": "text",
            "label": "vendor_sub_category_id"
          },
          {
            "model": "image",
            "type": "text",
            "label": "image"
          },
          {
            "model": "city_id",
            "type": "text",
            "label": "city_id"
          },
          {
            "model": "hood_id",
            "type": "text",
            "label": "hood_id"
          },
          {
            "model": "location",
            "type": "text",
            "label": "location"
          },
          {
            "model": "registry_id",
            "type": "text",
            "label": "registry_id"
          },
          {
            "model": "official_name",
            "type": "text",
            "label": "official_name"
          },
          {
            "model": "official_tel",
            "type": "text",
            "label": "official_tel"
          },
          {
            "model": "official_email",
            "type": "text",
            "label": "official_email"
          },
          {
            model: "banners",
            type: "select",
            options: (() => {
              return this.banners.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.banners",
          },

          {
            model: "coupons",
            type: "select",
            options: (() => {
              return this.coupons.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.coupons",
          },

          {
            model: "notfication_settings",
            type: "select",
            options: (() => {
              return this.notfication_settings.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.notfication_settings",
          },

          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles",
          },

          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles",
          },

          {
            model: "products",
            type: "select",
            options: (() => {
              return this.products.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.products",
          },

          {
            model: "products_size",
            type: "select",
            options: (() => {
              return this.products_size.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.products_size",
          },

          {
            model: "users_roles",
            type: "select",
            options: (() => {
              return this.users_roles.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.users_roles",
          },

          {
            model: "vendor_balance",
            type: "select",
            options: (() => {
              return this.vendor_balance.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_balance",
          },

          {
            model: "vendor_currenices",
            type: "select",
            options: (() => {
              return this.vendor_currenices.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_currenices",
          },

          {
            model: "vendor_marketers",
            type: "select",
            options: (() => {
              return this.vendor_marketers.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_marketers",
          },

          {
            model: "vendor_subscriptions",
            type: "select",
            options: (() => {
              return this.vendor_subscriptions.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.vendor_subscriptions",
          },


        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("users-dateils", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.UsersDateilsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("users-dateils/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.UsersDateils = res.data;
          console.log("######## files", this.UsersDateils);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.UsersDateilsSearchMode = false;
      this.get(this.page);
    },

    getUsersDateils() {
      this.http.get("users-dateils").then((res) => {
        this.UsersDateils = res.data;
      });
    },
    deleteUsersDateils(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("users-dateils", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("users-dateils/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.UsersDateils = res.data;
          res.data.forEach((item) => {
            this.banners.push(item.banners)
          })

          res.data.forEach((item) => {
            this.coupons.push(item.coupons)
          })

          res.data.forEach((item) => {
            this.notfication_settings.push(item.notfication_settings)
          })

          res.data.forEach((item) => {
            this.order_deatiles.push(item.order_deatiles)
          })

          res.data.forEach((item) => {
            this.order_deatiles.push(item.order_deatiles)
          })

          res.data.forEach((item) => {
            this.products.push(item.products)
          })

          res.data.forEach((item) => {
            this.products_size.push(item.products_size)
          })

          res.data.forEach((item) => {
            this.users_roles.push(item.users_roles)
          })

          res.data.forEach((item) => {
            this.vendor_balance.push(item.vendor_balance)
          })

          res.data.forEach((item) => {
            this.vendor_currenices.push(item.vendor_currenices)
          })

          res.data.forEach((item) => {
            this.vendor_marketers.push(item.vendor_marketers)
          })

          res.data.forEach((item) => {
            this.vendor_subscriptions.push(item.vendor_subscriptions)
          })


        });
    },
  },
  created() {
    this.get(1);
    this.getUsersDateils();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.users_dateils.text')" />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input @keyup="search()" type="text" class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
              style="background-color: #2a3042 !important" />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button class="btn-close text-light" v-if="UsersDateilsSearchMode" @click="cancelappsearchMode()"></button>
        <button type="button" @click="addUsersDateils()" class="btn btn-light float-end mb-4">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope='col'>{{ $t('users_dateils.id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.user_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.is_main') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.brand_name') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.vendor_category_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.vendor_sub_category_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.image') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.city_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.hood_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.location') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.registry_id') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.official_name') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.official_tel') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.official_email') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.created') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.updated') }}
          </th>
          <th scope='col'>{{ $t('users_dateils.operations') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in UsersDateils" :key="app" class="text-center">
          <td>{{ app.user_id }}</td>
          <td>{{ app.is_main }}</td>
          <td>{{ app.brand_name }}</td>
          <td>{{ app.vendor_category_id }}</td>
          <td>{{ app.vendor_sub_category_id }}</td>
          <td>{{ app.image }}</td>
          <td>{{ app.city_id }}</td>
          <td>{{ app.hood_id }}</td>
          <td>{{ app.location }}</td>
          <td>{{ app.registry_id }}</td>
          <td>{{ app.official_name }}</td>
          <td>{{ app.official_tel }}</td>
          <td>{{ app.official_email }}</td>
          <td>{{ app.created.split('T')[0] }}</td>
          <td>{{ app.updated.split('T')[0] }}</td>

          <td class="d-flex">
            <a class="btn btn-primary mx-1" @click="editUsersDateils(app)" href="javascript: void(0);" role="button">{{
              $t("popups.edit") }}</a>
            <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteUsersDateils(app)">{{
              $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  paginate     -->
    <ul class="paginate paginate-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
          }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</Layout></template>

<style scoped>.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}</style>




